import { Injectable } from '@angular/core';
import { getBrowserLang, TranslocoService } from '@jsverse/transloco';
import { TranslationApiService } from '../api/translation-api.service';
import { Observable } from 'rxjs';
import { AuthStorageService } from '@ess-front/shared';

@Injectable({
  providedIn: 'root',
})
export class TranslationDomainService {
  constructor(
    private authStorageService: AuthStorageService,
    private translocoService: TranslocoService,
    private translationApiService: TranslationApiService,
  ) {}

  translate(key: string): string {
    return this.translocoService.translate(key);
  }

  translateObject<T extends object>(obj: T, properties: keyof T | (keyof T)[]) {
    if (Array.isArray(properties)) {
      let newObj = { ...obj };
      properties.forEach(property => {
        newObj = { ...newObj, [property]: this.translate(`${obj[property]}`) };
      });
      return newObj;
    }
    return { ...obj, [properties]: this.translate(`${obj[properties]}`) };
  }

  translateObjectArray<T extends object>(objArray: T[], property: keyof T | (keyof T)[]) {
    return objArray.map(obj => this.translateObject(obj, property));
  }

  getUserLanguage(): string | null {
    return this.authStorageService.getLanguage();
  }
  getTripLanguage$(tripHash: string): Observable<string> {
    return this.translationApiService.getTripLanguage$(tripHash);
  }

  getBrowserLanguage(): string {
    return getBrowserLang() ?? 'en';
  }

  getActiveLanguage(): string {
    return this.translocoService.getActiveLang();
  }

  setActiveLanguage(language: string): void {
    this.translocoService.setActiveLang(language);
  }

  isLanguageSupported(language: string): boolean {
    return this.translocoService.isLang(language);
  }
}
