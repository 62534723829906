import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { Observable, of, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TranslationApiService {
  private tripHashLanguageMap = new Map<string, string>();

  constructor(private httpClient: HttpClient) {}

  getTripLanguage$(tripHash: string): Observable<string> {
    const cachedLanguage = this.tripHashLanguageMap.get(tripHash);
    if (cachedLanguage) {
      return of(cachedLanguage);
    } else {
      const url = `${environment.apiEnv}api/v2/bookings/${tripHash}/language/`;
      return this.httpClient.get<{ language: string }>(url).pipe(
        map(languageBE => languageBE.language),
        tap(language => this.tripHashLanguageMap.set(tripHash, language)),
        catchError(() => of('en')),
      );
    }
  }
}
